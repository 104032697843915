import { inject, NgModule } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [ErrorHandlerService],
})
export class ErrorHandlerModule {
  constructor(_: ErrorHandlerService) {
    const existsAtParent = inject(ErrorHandlerModule, {
      skipSelf: true,
      optional: true,
    });
    if (existsAtParent) {
      throw new Error('ErrorHandlerModule already exists');
    }
  }
}
