import { NgModule, provideAppInitializer } from '@angular/core';

import { i18nextInit } from './translate.init';

@NgModule({
  providers: [
    provideAppInitializer(() => {
      const initializerFn = i18nextInit();
      return initializerFn();
    }),
  ],
})
export class TranslateModule {}
