import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CrmCacheInterceptorService } from './cache-interceptor.service';
import { CacheRegisterService } from './cache-register.service';
import {
  CacheConfigToken,
  CrmCacheConfig,
  defaultCacheConfiguration,
} from './model/cache-config';

@NgModule({
  imports: [],
  providers: [
    CacheRegisterService,
    CrmCacheInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CrmCacheInterceptorService,
      multi: true,
    },
    {
      provide: CacheConfigToken,
      useValue: defaultCacheConfiguration,
    },
  ],
})
export class CacheModule {
  public static forRoot(
    config: CrmCacheConfig,
  ): ModuleWithProviders<CacheModule> {
    return {
      ngModule: CacheModule,
      providers: [
        {
          provide: CacheConfigToken,
          useValue: config,
        },
      ],
    };
  }
}
