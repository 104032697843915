import { inject, Injectable } from '@angular/core';
import { AppwriteException } from 'appwrite';
import { Router } from '@angular/router';

import { AppRoutes } from '../../app.routes';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  private static _instance: ErrorHandlerService;
  private router = inject(Router);

  static get instance() {
    return ErrorHandlerService._instance;
  }

  constructor() {
    if (!ErrorHandlerService._instance) {
      ErrorHandlerService._instance = this;
    }
  }

  handleError(error: unknown) {
    if (error instanceof AppwriteException) {
      if (error.code === 401) {
        this.router.navigate([AppRoutes.auth, 'login']);
        return;
      }
    }
  }
}
