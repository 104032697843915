import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { CrmCacheInterceptorService } from '@app/core/cache/cache-interceptor.service';
import { Observable } from 'rxjs';

export const cacheInterceptor: HttpInterceptorFn = (
  httpRequest: HttpRequest<unknown>,
  handler: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const cacheService = inject(CrmCacheInterceptorService);
  return cacheService.intercept(httpRequest, handler);
};
