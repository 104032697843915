import { APP_INITIALIZER, NgModule } from '@angular/core';

import { i18nextInit } from './translate.init';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: i18nextInit,
      multi: true,
    },
  ],
})
export class TranslateModule {}
