import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from '@environments/environment';

const googleMapsInitializer = () => {
  if (
    'loadGoogleMapsScript' in window &&
    typeof window.loadGoogleMapsScript === 'function'
  ) {
    window.loadGoogleMapsScript({
      v: 'weekly',
      key: environment.googleMapsKey,
    });
  }
};

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => googleMapsInitializer,
      multi: true,
    },
  ],
})
export class GoogleMapsModule {}
