import { InjectionToken } from '@angular/core';

export interface CrmCacheConfig {
  /**
   * List of cached URLs (or its parts) that will be cached upon intercepting
   * their requests.
   * Default: ['profile'] for user profile caching
   */
  cacheURLs?: string[];
  /**
   * TimeToLive in ms for cached records. After this time, records are discarded.
   * Default: 1000
   */
  recordTTL?: number;
  /**
   * Flag to enable debug logs
   */
  debug?: boolean;
}

export const defaultCacheConfiguration: CrmCacheConfig = {
  recordTTL: 3000,
  debug: false,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CacheConfigToken = new InjectionToken<CrmCacheConfig>(
  'cache config token',
);
