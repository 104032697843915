import { NgModule } from '@angular/core';
import { PushMessagingService } from '@app/core/messaging/push-messaging.service';
import { MessageService } from '@app/core/message/message.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [PushMessagingService, MessageService],
})
/**
 * Firebase PUSH message features
 */
export class MessagingModule {}
