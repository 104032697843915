import {
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isObservable, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CacheRegisterService } from './cache-register.service';
import { CrmCache } from './model/cache';
import {
  CacheConfigToken,
  CrmCacheConfig,
  defaultCacheConfiguration,
} from './model/cache-config';

const DEFAULT_TTL = 1000;

@Injectable()
export class CrmCacheInterceptorService {
  private readonly defaultTTL: number = DEFAULT_TTL;
  private readonly debug: boolean;

  private cachedData = new Map<string, CrmCache>();

  constructor(
    protected cacheRegistrationService: CacheRegisterService,
    @Inject(CacheConfigToken) private cacheConfig: CrmCacheConfig,
  ) {
    const config: CrmCacheConfig = {
      ...defaultCacheConfiguration,
      ...(this.cacheConfig ?? {}),
    };

    this.defaultTTL = config.recordTTL ?? DEFAULT_TTL;
    this.debug = config.debug ?? false;

    this.log([
      'CONFIG - ',
      `TTL: ${this.defaultTTL}`,
      `URLS: ${this.cacheRegistrationService.cachedPaths.join(', ')}`,
    ]);
  }

  intercept(
    httpRequest: HttpRequest<unknown>,
    handler: HttpHandlerFn,
  ): Observable<HttpEvent<unknown>> {
    const url = httpRequest.url;
    const urlWithParams = httpRequest.urlWithParams;
    const urlRegistered = this.cacheRegistrationService.isUrlRegistered(url);

    // Don't cache if it's not a GET request
    if (httpRequest.method !== 'GET') {
      this.log(['not GET request', urlWithParams]);
      return handler(httpRequest);
    }

    // Don't cache if URI is not supposed to be cached
    if (!urlRegistered) {
      this.log(['not registered', urlWithParams]);
      return handler(httpRequest);
    }

    // Also leave scope of resetting already cached data for a URI
    if (httpRequest.headers.get('reset-cache')) {
      this.log(['delete', 'headers required', urlWithParams]);
      this.cachedData.delete(urlWithParams);
    }

    // Checked if there is cached data for this URI
    let lastResponse = this.cachedData.get(urlWithParams);

    // Check last response data are expired
    const now = Date.now();
    if (lastResponse && now > lastResponse.expiresAt) {
      this.log(['delete', 'expired', urlWithParams]);
      this.cachedData.delete(urlWithParams);
      lastResponse = undefined;
    }

    if (lastResponse) {
      // In case of parallel requests to same URI,
      // return the request already in progress
      // otherwise return the last cached data
      lastResponse.numberOfGet += 1;
      this.log(['cached response', urlWithParams]);
      return (
        isObservable(lastResponse.data)
          ? lastResponse.data
          : of(lastResponse.data)
      ) as Observable<HttpEvent<unknown>>;
    }

    this.log(['--------------']);

    // Create Subject for request in progress for data to be cached there, for parallel requests
    const handle$ = new Subject();
    this.log(['set handle', urlWithParams]);
    this.cachedData.set(urlWithParams, {
      data: handle$.asObservable(),
      expiresAt: this.ttl(),
      numberOfGet: 0,
    });

    // If the request of going through for first time
    // then let the request proceed and cache the response
    return handler(httpRequest).pipe(
      tap((stateEvent) => {
        if (stateEvent instanceof HttpResponse) {
          this.cachedData.set(urlWithParams, {
            data: stateEvent.clone(),
            expiresAt: this.ttl(),
            numberOfGet: 0,
          });
          handle$.next(stateEvent.clone());
          handle$.complete();
          this.log(['set', urlWithParams]);
        }
      }),
    );
  }

  protected ttl() {
    return Date.now() + this.defaultTTL;
  }

  protected log(msg: string[]) {
    if (this.debug) {
      console.log(`[CACHE]: `, msg.join('; '), { cache: this.cachedData });
    }
  }
}
