import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';

export function i18nextInit() {
  return () => {
    const [lang] = navigator.language.split('-');

    return i18next.use(HttpApi).init(
      {
        supportedLngs: ['en', 'sk', 'de'],
        fallbackLng: 'en',
        lng: lang,
        debug: true,
        backend: {
          loadPath: 'locales/{{lng}}.json',
        },
      },
      (err: unknown) => {
        if (err) {
          return console.log('something went wrong loading', err);
        }
      },
    );
  };
}
