import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { GoogleMapsModule } from '@app/core/google-maps/google-maps.module';
import {
  provideAngularQuery,
  QueryClient,
} from '@tanstack/angular-query-experimental';
import { provideServiceWorker } from '@angular/service-worker';
import { CacheModule } from '@app/core/cache/cache.module';
import { cacheInterceptor } from '@app/core/cache/cache.interceptor';
import { MessagingModule } from '@app/core/messaging/messaging.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { ErrorHandlerModule } from '@app/core/error-handler/error-handler.module';
import { environment } from '@environments/environment';

import { TranslateModule } from './core/translate/translate.module';
import { routes } from './app.routes';

registerLocaleData(en);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
    ),
    importProvidersFrom(TranslateModule),
    importProvidersFrom(GoogleMapsModule),
    importProvidersFrom(MessagingModule),
    provideNzI18n(en_US),
    importProvidersFrom(FormsModule),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([cacheInterceptor])),
    importProvidersFrom(ErrorHandlerModule),
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            throwOnError: (err) => {
              ErrorHandlerService.instance.handleError(err);
              return false;
            },
          },
        },
      }),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: true,
      // enabled: environment.env !== 'DEV',
      registrationStrategy: 'registerWhenStable:30000',
    }),
    importProvidersFrom(
      CacheModule.forRoot({
        // debug: false,
        debug: environment.env === 'DEV',
        recordTTL: 10 * 60 * 1000,
      }),
    ),
    // todo: need global 401 error handling
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
  ],
};
